export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'cn',
  messages: {
    cn: {
      /* 导航栏 */
      首页: '首页',
      课程产品和服务: '课程产品和服务',
      思科认证: '思科认证',
      华为认证: '华为认证',
      深信服认证: '深信服认证',
      锐捷认证: '锐捷认证',
      奇安信认证: '奇安信认证',

      最新资讯: '最新资讯',
      行业资讯: '行业资讯',
      认证信息: '认证信息',
      招聘需求: '招聘需求',

      合作共赢: '合作共赢',
      渠道合作: '渠道合作',
      生态合作: '生态合作',

      关于我们: '关于我们',
      公司介绍: '公司介绍',
      团队师资: '团队师资',
      师资团队: '师资团队',
      合资企业: '合资企业',
      查看更多: '查看更多',
      搜索: '搜索',
      了解详情: '了解详情',
      立即预订: '立即预定',
      更多: '更多',
      加入我们: '加入我们',
      开启新的工作: '开启新的工作',
      城市: '城市',
      职位类别: '职位类别',
      上一页: '上一页',
      下一页: '下一页',
      职位描述: '职位描述',
      职位要求: '职位要求',
      立即投递: '立即投递',
      难度: '难度',
      详情: '详情',
      主题: '主题',
      班型: '班型',
      班级类型: '班级类型',
      地点: '地点',
      试听预约: '试听预约',
      科目: '科目',
      硬件设施: '硬件设施',
      课程特色: '课程特色',
      立即预约: '立即预约',
      职位: '职位',
      证书: '证书',
      从业经验: '从业经验',
      行业动态: '行业动态',
      地方: '地方',
      岗位: '岗位',
      核心课程: '核心课程',
      '报名/详情': '报名/详情',
      申请合作: '申请合作'
    },
    en: {
      /* 导航栏 */
      首页: 'HOME',
      课程产品和服务: 'COURSE PRODUCTS AND SERVICES',

      思科认证: 'Cisco Certification',
      华为认证: 'Huawei Certification',
      深信服认证: 'Sangfor Certification',
      锐捷认证: 'Ruijie Certification',
      奇安信认证: 'QiAnXin  Certification',

      最新资讯: 'LATEST NEWS',
      行业资讯: 'Industry Information',
      认证信息: 'Certification information',
      招聘需求: 'Recruitment needs',

      合作共赢: 'WIN-WIN',
      渠道合作: 'Channel cooperation',
      生态合作: 'ecological cooperation',
      企业培训: 'Corporate Training',
      关于我们: 'ABOUT US',
      公司介绍: 'Company Introduction',
      团队师资: 'Team teachers',
      合资企业: 'joint venture',
      查看更多: 'VIEW MORE',
      搜索: 'SEARCH',
      了解详情: 'LEARN MORE',
      立即预订: 'RESERVATION NOW',
      更多: 'MORE',
      加入我们: 'JOIN US',
      开启新的工作: 'Start a new job',
      城市: 'City',
      职位类别: 'Job category',
      上一页: 'Pre',
      下一页: 'Next',
      职位描述: 'Job description',
      职位要求: 'Job requirements',
      立即投递: 'Apply now',
      难度: 'Difficulty',
      详情: 'Details',
      主题: 'Theme',
      班级类型: 'CLASS TYPE',
      班型: 'CLASS TYPE',
      地方: 'PLACE',
      地点: 'Location',
      试听预约: 'Audition appointment',
      科目: 'Subject',
      硬件设施: 'Hardware facilities',
      软件配套: 'Software matching',
      课程特色: 'Course features',
      立即预约: 'RESERVATION NOW',
      师资团队: 'Faculty Team',
      职位: 'Position',
      证书: 'Certificate',
      从业经验: 'work experience',
      行业动态: 'Industry dynamics',
      岗位: 'POST',
      核心课程: 'Main course',
      '报名/详情': 'REGISTER NOW',
      申请合作: 'Apply for cooperation'
    },
    ph: {
      首页: 'Unang pahina',
      课程产品和服务: 'Mga kurso, produkto, serbisyo',
      思科认证: 'Certification ng Cisco',
      华为认证: 'Certification ng Huawei',
      深信服认证: 'Certification ng Sangfor',
      锐捷认证: 'Certification ng Ruijie',
      奇安信认证: 'Certification ng QiAnXin',

      最新资讯: 'Pinakabagong balita',
      行业资讯: 'Impormasyon sa Industriya',
      认证信息: 'Impormasyon sa Sertipikasyon',
      招聘需求: 'Pangangailangan sa pangangalap',

      合作共赢: 'manalo-manalo',
      渠道合作: 'Pakikipagtulungan sa channel',
      生态合作: 'Ekolohikal na pagtutulungan',
      企业培训: 'Pagsasanay ng mga kumpanya',
      关于我们: 'tungkol sa atin',
      公司介绍: 'Pagpapakilala ng Kumpanya',
      团队师资: 'Mga guro ng pangkat',
      合资企业: 'Joint venture',
      查看更多: 'tingnan pa',
      搜索: 'paghahanap',
      了解详情: 'Matuto pa',
      立即预订: 'Reserbasyon ngayon',
      更多: 'Higit pa',
      加入我们: 'Sumali ka',
      开启新的工作: 'Magsimula ng bagong trabaho',
      城市: 'lungsod',
      职位类别: 'Kategorya ng trabaho',
      上一页: 'Nakaraang pahina',
      下一页: 'Susunod na pahina',
      职位描述: 'Deskripsyon ng trabaho',
      职位要求: 'Mga kinakailangan sa trabaho',
      立即投递: 'Mag-apply ngayon',
      难度: 'Kahirapan',
      详情: 'Mga Detalye',
      主题: 'Tema',
      班级类型: 'Uri ng klase',
      班型: 'Uri ng klase',
      地方: 'Lugar',
      地点: 'Lokasyon',
      试听预约: 'Appointment sa audition',
      科目: 'Paksa',
      硬件设施: 'Mga pasilidad ng hardware',
      软件配套: 'Pagtutugma ng software',
      课程特色: 'Mga tampok ng kurso',
      立即预约: 'Reserbasyon ngayon',
      师资团队: 'Pangkat ng mga guro',
      职位: 'Posisyon',
      证书: 'Sertipiko',
      从业经验: 'Karanasan sa trabaho',
      行业动态: 'Dinamika ng industriya',
      岗位: 'Post',
      核心课程: 'Mga pangunahing kurso',
      '报名/详情': 'Mag-rehistro na ngayon',
      申请合作: 'Mag-apply para sa kooperasyon'
    }
  }
}))
